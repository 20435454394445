/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js
 * - /npm/jquery-ui@1.13.3/dist/jquery-ui.min.js
 * - /npm/sprintf-js@1.1.3/src/sprintf.min.js
 * - /npm/apexcharts@3.48.0/dist/apexcharts.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
